import axios, { AxiosRequestConfig } from "axios";

export default class BaseApi {
  accessToken: string | null;

  constructor(accessToken?: string) {
    if (accessToken) this.accessToken = accessToken;
    else this.accessToken = null;
  }

  protected get axiosApiConfig() {
    return {
      baseURL: process.env.API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : "",
      },
      responseType: "json",
    } as AxiosRequestConfig;
  }

  protected get get() {
    return axios.create(this.axiosApiConfig).get;
  }

  protected get post() {
    return axios.create(this.axiosApiConfig).post;
  }

  protected get put() {
    return axios.create(this.axiosApiConfig).put;
  }

  protected get patch() {
    return axios.create(this.axiosApiConfig).patch;
  }

  protected get delete() {
    return axios.create(this.axiosApiConfig).delete;
  }
}
